import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter2_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lwc+2.2.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#75B8E4",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Ongoing Research</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14B Paragraph With Bold Italic Spans */}

                  <div className="lego l00paragraph">
                    <p className="legoMargin2">
                      At the Living Well Center, we keep close track of{" "}
                      <span className="bold italic">
                        each patient’s journey to help us improve the care
                        experience
                      </span>{" "}
                      of our current and future patients.
                    </p>
                    <p className="legoMargin2">
                      Our goal is to understand what works well and what we
                      can do to support patients even more as they work toward
                      their lifestyle goals.{" "}
                    </p>
                    <p className="legoMargin2">
                      <span className="bold italic">
                        So far, some of what we’ve learned is that patients tend
                        to be more successful when they:
                      </span>{" "}
                    </p>
                    {/* L53 Bullet List */}
                    <div className="lego l053 smallFont legoMargin3">
                      <ul className="w-100">
                        <li className="w-100">
                          Focus on improving function, rather than reducing pain
                        </li>
                        <li className="w-100">
                          Focus on improving quality of life, rather than
                          lowering numbers on a scale
                        </li>
                      </ul>
                    </div>
                    {/* End L53 Bullet List */}
                    <p>
                      <span className="bold italic">
                        Through ongoing research, we are working to:
                      </span>{" "}
                    </p>
                    {/* L53 Bullet List */}
                    <div className="lego l053 smallFont">
                      <ul className="w-100">
                        <li className="w-100">
                          Identify what the “key ingredients” are to empower
                          patients to meet their lifestyle goals
                        </li>
                        <li className="w-100">
                          Find more ways to increase access to lifestyle
                          medicine tools, no matter where a patient lives or
                          what insurance they have (or don’t have)
                        </li>
                        <li className="w-100">
                          Use lifestyle medicine to speed and smooth recovery
                          after spine surgery
                        </li>
                        <li className="w-100">
                          Use lifestyle medicine to support people with Long
                          COVID
                        </li>
                        <li className="w-100">
                          Use lifestyle medicine to support patients through
                          cancer journeys
                        </li>
                      </ul>
                    </div>
                    {/* End L53 Bullet List */}
                  </div>
                  {/* End L14B Paragraph With Bold Italic Spans */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066A legoMargin4">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps2">
                      JOINING A RESEARCH STUDY
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L11A Image Max Width 400px */}
                  <div className="lego l011 legoMargin3">
                    <img
                      alt=""
                      className="imageW100 maxWidth500 noBorderRadius"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/24317d5d0ea84bcf9bfc50bc54788564.png"
                    />
                  </div>
                  {/* End L11A Image Max Width 400px */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph">
                    <p className="legoMargin2">
                      We have several prior and ongoing research studies. 
                      <br />
                    </p>
                  </div>

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      {" "}
                      E-mail Adriana Martin at 
                      <a
                        className="blueLinkfont18"
                        href="mailto:AZMartin@wustl.edu"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        AZMartin@wustl.edu 
                      </a>{" "}
                      if you would like to be notified when you may qualify for
                      one of our studies in the future. 
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      {" "}
                      You can also check out our  
                      <a
                        className="blueLinkfont18"
                        href="https://sites.wustl.edu/wuvfh/F"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Volunteer for Health
                      </a>{" "}
                      page to learn whether you may qualify for other research
                      studies at Washington University.
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      {" "}
                      If you are a 45-year or older adult living with chronic
                      pain and symptoms of depression and/or anxiety, you could
                      be eligible to join a study by Washington University in
                      St. Louis to shape support apps for people like you. Your
                      input is crucial for enhancing technologies to address
                      mental health in chronic pain. Participants who complete
                      the study will receive a $30 gift card for their time. For
                      more information, contact Adriana Martin at 314-530-9023,
                      or send an email to: {" "}
                      <a
                        className="blueLinkfont18"
                        href="mailto:AZMartin@wustl.edu"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        AZMartin@wustl.edu 
                      </a>{" "}
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin4">
                    <p>
                      To learn more and complete online screening to see if you
                      are eligible to participate, go online to:{" "}
                      <a
                        className="blueLinkfont18"
                        href="https://redcap.wustl.edu/redcap/surveys/?s=HJFTMX3CMTT3NMMN"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                         https://redcap.link/usable
                      </a>{" "}
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter2_2;
